import { $gettext, $pgettext } from '@/utils/gettext';

const translationFixtures = {
  apartments: $gettext('Has apartments'),
  baby_change: $gettext('Baby changing room'),
  bicycle_rent: $gettext('Bicycle rent'),
  boat_hire: $gettext('Boat hire'),
  bread_at_campsite: $gettext('Bread sold on-site'),
  bus_bar: $gettext('Electric hook-ups'),
  campfire_at_pitch_allowed: $gettext('Campfire at pitch allowed'),
  car_allowed_at_pitch: $gettext('Car allowed at pitch'),
  car_free: $gettext('Car free'),
  caravan: $gettext('Rental caravans'),
  caravan_pitches_outside_gate: $gettext('Caravan pitches outside the gate'),
  central_bonfire_area: $gettext('Central bonfire area'),
  charcoal_grill_allowed: $gettext('Carcoal grill allowed'),
  childrens_playground: $gettext('Children\'s playground'),
  climbing: $gettext('Climbing'),
  cooking_facilities: $gettext('Cooking facilities'),
  count_rentable_bathrooms: $gettext('Baths for hire'),
  count_space_cars: $gettext('Max. number of pitches'),
  count_space_tourism: $gettext('Number of tourist pitches'),
  count_space_tourism_separated: $gettext('Divided tourist pitches'),
  count_washing_cabins: $gettext('Individual washing cubicles'),
  cross_country_skiing: $gettext('Cross country skiing'),
  crosscountry: $gettext('CrossCountry'),
  culinary: $gettext('Culinary'),
  culture: $gettext('Culture'),
  cycling: $gettext('Cycling'),
  diving: $gettext('Diving'),
  diving_station: $gettext('Diving station'),
  dog_bathing: $gettext('Dog bathing'),
  dog_meadow: $gettext('Dog meadow'),
  dog_showers: $gettext('Dog showers'),
  dogs_allowed_in_rented_accomodation: $gettext('Dogs allowed in rented accomodation'),
  dogs_allowed_off_season: $gettext('Dogs allowed in off season'),
  dogs_allowed_season: $gettext('Dogs allowed in main season'),
  dogs_not_allowed: $gettext('Dogs always forbidden'),
  dogs_welcome: $gettext('Dogs welcome'),
  drying_room: $gettext('Drying room'),
  durable_camping: $gettext('Permanent camping'),
  firewood: $gettext('Firewood'),
  fishing: $gettext('Fishing'),
  fishing_bw: $gettext('Fishing'),
  food_supply: $gettext('Grocery shop'),
  gas_bottle_exchange: $gettext('Gas bottle exchange'),
  gas_connection_at_pitch: $gettext('Gas connection at pitch'),
  golf: $gettext('Golf'),
  ground_composition_predominantly: $gettext('Ground predominantly'),
  handicapped_accessible_water_entrance: $gettext('Has a handicapped accessible water entrance'),
  hiking: $gettext('Hiking'),
  horseback_riding: $gettext('Horseback riding'),
  horsebackriding: $gettext('Horsebackriding'),
  hot_springs: $gettext('Hot springs'),
  indoor_gaming_possibilities: $gettext('Indoor gaming possibilities'),
  internet_terminal: $gettext('Internet terminal'),
  is_in_the_mountains: $gettext('Is in the mountains'),
  kayak: $gettext('Kayak'),
  kids_animation: $gettext('Kids animation'),
  laundry_driers: $gettext('Tumble driers'),
  lockers: $gettext('Lockers'),
  lounge: $gettext('Lounge'),
  mini_camping: $gettext('Minicamping'),
  mini_golf: $gettext('Minigolf'),
  mobile_bungalows: $gettext('Mobile bungalows'),
  most_ways_paved: $gettext('Most ways are paved'),
  motorboat: $gettext('Motorboat'),
  motorcycle: $gettext('Motorcycle'),
  motorhome_pitches_outside_gate: $gettext('Motorhome pitches outside the gate'),
  mountainbiking: $gettext('Mountainbiking'),
  nudism: $gettext('Nudism'),
  nudist_beach: $gettext('Nudist beach'),
  paragliding: $gettext('Paragliding'),
  party: $gettext('Party'),
  petting_zoo: $gettext('Petting zoo'),
  rating_avg_camper_with_tent_friendly: $gettext('Visitors with tents'),
  rating_avg_couple_friendly: $gettext('Couples'),
  rating_avg_guests_with_dogs_friendly: $gettext('Visitors with dogs'),
  rating_avg_kid_friendly18: $gettext('Families with children under 18'),
  rating_avg_kid_friendly6: $gettext('Families with children under 6'),
  rating_avg_kid_friendly: $gettext('Families with children under 12'),
  rating_avg_senior_friendly: $gettext('Senior citizens'),
  rating_avg_youth_friendly: $gettext('Youth groups'),
  relaxing: $gettext('Relaxing'),
  rent_cabins: $gettext('Rent cabins'),
  restaurant: $gettext('Restaurant'),
  sailing: $gettext('Sailing'),
  sandbeach: $gettext('Sand beach'),
  sanitarian_handicapped: $gettext('Barrier-free sanitary facilities'),
  sauna: $gettext('Sauna'),
  sewage_connection_at_pitch: $gettext('Sewage connection at pitch'),
  shadow: $gettext('Shade on pitches'),
  sightseeing: $gettext('Sightseeing'),
  ski: $gettext('Ski'),
  skilift: $gettext('Ski lift'),
  slipway: $gettext('Slipway'),
  snack: $gettext('Snack'),
  spaceyouthgroup: $gettext('Separate space for youth groups'),
  strolling: $gettext('Strolling'),
  surfing: $gettext('Surfing'),
  swimming: $gettext('Swimming'),
  swimming_nature: $gettext('Swimming in nature'),
  swimming_pool_indoor: $gettext('Indoor pool'),
  swimming_pool_outdoor: $gettext('Outdoor pool'),
  table_tennis: $gettext('Table tennis'),
  tables_and_benches: $gettext('Tables and benches'),
  tennis: $gettext('Tennis'),
  tents: $gettext('Rental tents'),
  tv_connection_at_pitch: $gettext('TV connection at pitch'),
  ver_entsorgungs_wohnmobile: $gettext('Motorhome service point'),
  volleyball: $gettext('Volleyball'),
  washingmachines: $gettext('Washingmachines'),
  water_connection_at_pitch: $gettext('Water connection at pitch'),
  water_slide: $gettext('Waterslide'),
  wellness: $gettext('Wellness'),
  wheelchair_ramps: $gettext('Wheelchair ramps'),
  wifi: $gettext('Wifi'),
  wifi_costs: $gettext('Wifi costs'),
  windsurfing: $pgettext('Key windsurfing for Sailing & surfing', 'Windsurfing'),
  winter_camping: $gettext('Wintercamping'),
};

export const languages = [
  {
    code: 'de',
    name: $gettext('German'),
  },
  {
    code: 'en',
    name: $gettext('English'),
  },
  {
    code: 'nl',
    name: $gettext('Dutch'),
  },
  {
    code: 'fr',
    name: $gettext('French'),
  },
  {
    code: 'it',
    name: $gettext('Italian'),
  },
  {
    code: 'es',
    name: $gettext('Spanish'),
  },
  {
    code: 'da',
    name: $gettext('Danish'),
  },
  {
    code: 'no',
    name: $gettext('Norwegian'),
  },
  {
    code: 'pl',
    name: $gettext('Polish'),
  },
  {
    code: 'cs',
    name: $gettext('Czech'),
  },
  {
    code: 'bs',
    name: $gettext('Bosnian'),
  },
  {
    code: 'et',
    name: $gettext('Estonian'),
  },
  {
    code: 'hr',
    name: $gettext('Croatian'),
  },
  {
    code: 'lv',
    name: $gettext('Latvian'),
  },
  {
    code: 'lt',
    name: $gettext('Lithuanian'),
  },
  {
    code: 'hu',
    name: $gettext('Hungarian'),
  },
  {
    code: 'pt',
    name: $gettext('Portuguese'),
  },
  {
    code: 'ro',
    name: $gettext('Romanian'),
  },
  {
    code: 'sk',
    name: $gettext('Slovak'),
  },
  {
    code: 'sl',
    name: $gettext('Slovene'),
  },
  {
    code: 'sr',
    name: $gettext('Serbian'),
  },
  {
    code: 'fi',
    name: $gettext('Finnish'),
  },
  {
    code: 'sv',
    name: $gettext('Swedish'),
  },
  {
    code: 'tr',
    name: $gettext('Turkish'),
  },
  {
    code: 'el',
    name: $gettext('Greek'),
  },
  {
    code: 'bg',
    name: $gettext('Bulgarian'),
  },
  {
    code: 'ru',
    name: $gettext('Russian'),
  },
];

export const searchUiAges = [
  { key: 0, value: $gettext('Under 1') },
  { key: 1, value: $gettext('1 Year') },
  { key: 2, value: $gettext('2 Years') },
  { key: 3, value: $gettext('3 Years') },
  { key: 4, value: $gettext('4 Years') },
  { key: 5, value: $gettext('5 Years') },
  { key: 6, value: $gettext('6 Years') },
  { key: 7, value: $gettext('7 Years') },
  { key: 8, value: $gettext('8 Years') },
  { key: 9, value: $gettext('9 Years') },
  { key: 10, value: $gettext('10 Years') },
  { key: 11, value: $gettext('11 Years') },
  { key: 12, value: $gettext('12 Years') },
  { key: 13, value: $gettext('13 Years') },
  { key: 14, value: $gettext('14 Years') },
  { key: 15, value: $gettext('15 Years') },
  { key: 16, value: $gettext('16 Years') },
  { key: 17, value: $gettext('17 Years') },
];

export const searchUiGuestAdult = {
  type: 'adults',
  description: $pgettext('description guest adult', '18 Years and older'),
  name: $pgettext('name guest adults', 'Adults'),
};

export const searchUiGuestChild = {
  type: 'children',
  description: $pgettext('description guest child', 'Under 18 Years'),
  name: $pgettext('name guest children', 'Children'),
};

// used on campsite tile
export const highlightActivity = {
  climbing: $pgettext('keep short for campsite tile', 'Recommended for climbing'),
  crosscountry: $pgettext('keep short for campsite tile', 'Recommended for crosscountry'),
  culinary: $pgettext('keep short for campsite tile', 'Recommended for culinary'),
  culture: $pgettext('keep short for campsite tile', 'Recommended for culture'),
  cycling: $pgettext('keep short for campsite tile', 'Recommended for cycling'),
  diving: $pgettext('keep short for campsite tile', 'Recommended for diving'),
  fishing: $pgettext('keep short for campsite tile', 'Recommended for fishing'),
  fishing_bw: $pgettext('keep short for campsite tile', 'Recommended for fishing'),
  golf: $pgettext('keep short for campsite tile', 'Recommended for golf'),
  hiking: $pgettext('keep short for campsite tile', 'Recommended for hiking'),
  horsebackriding: $pgettext('keep short for campsite tile', 'Recommended for horsebackriding'),
  kayak: $pgettext('keep short for campsite tile', 'Recommended for kayak'),
  motorboat: $pgettext('keep short for campsite tile', 'Recommended for motorboat'),
  motorcycle: $pgettext('keep short for campsite tile', 'Recommended for motorcycle'),
  mountainbiking: $pgettext('keep short for campsite tile', 'Recommended for mountainbiking'),
  paragliding: $pgettext('keep short for campsite tile', 'Recommended for paragliding'),
  party: $pgettext('keep short for campsite tile', 'Recommended for party'),
  relaxing: $pgettext('keep short for campsite tile', 'Recommended for relaxing'),
  sailing: $pgettext('keep short for campsite tile', 'Recommended for sailing'),
  sightseeing: $pgettext('keep short for campsite tile', 'Recommended for sightseeing'),
  ski: $pgettext('keep short for campsite tile', 'Recommended for ski'),
  strolling: $pgettext('keep short for campsite tile', 'Recommended for strolling'),
  surfing: $pgettext('keep short for campsite tile', 'Recommended for surfing'),
  swimming: $pgettext('keep short for campsite tile', 'Recommended for swimming'),
  tennis: $pgettext('keep short for campsite tile', 'Recommended for tennis'),
  wellness: $pgettext('keep short for campsite tile', 'Recommended for wellness'),
};

export const highlightRatings = {
  rating_avg_calmness: $pgettext('keep short for campsite tile', 'Especially quiet'),
  rating_avg_catering: $pgettext('keep short for campsite tile', 'Top rated catering'),
  rating_avg_cleanliness_overall: $pgettext('keep short for campsite tile', 'Top rated cleanliness'),
  rating_avg_cleanliness_sanitary: $pgettext('keep short for campsite tile', 'Extra clean sanitary facilities'),
  rating_avg_condition_rentable_accomodations: $pgettext(
    'keep short for campsite tile',
    'Rental accommodation in top condition',
  ),
  rating_avg_friendliness: $pgettext('keep short for campsite tile', 'Especially friendly'),
  rating_avg_infrastructure: $pgettext('keep short for campsite tile', 'Very good infrastructure'),
  rating_avg_leisure: $pgettext('keep short for campsite tile', 'Top rated for activities'),
  rating_avg_location: $pgettext('keep short for campsite tile', 'Particularly good location'),
  rating_avg_price_quality_ratio: $pgettext('keep short for campsite tile', 'Top price-performance ratio'),
  rating_avg_sanitary: $pgettext('keep short for campsite tile', 'Top rated sanitary'),
};

export const highlightTargetGroup = {
  rating_avg_camper_with_tent_friendly: $pgettext('keep short for campsite tile', 'Suitable for guests with tent'),
  rating_avg_couple_friendly: $pgettext('keep short for campsite tile', 'Suitable for couples'),
  rating_avg_guests_with_dogs_friendly: $pgettext('keep short for campsite tile', 'Suitable for guests with dog'),
  rating_avg_kid_friendly: $pgettext('keep short for campsite tile', 'Suitable for families with children <12'),
  rating_avg_kid_friendly18: $pgettext('keep short for campsite tile', 'Suitable for families with children <18'),
  rating_avg_kid_friendly6: $pgettext('keep short for campsite tile', 'Suitable for families with children <6'),
  rating_avg_senior_friendly: $pgettext('keep short for campsite tile', 'Suitable for senior citizens'),
  rating_avg_youth_friendly: $pgettext('keep short for campsite tile', 'Suitable for youth groups'),
};

/*
 Please note: We'll not use these for C'toutver for now, because the data we
 get from C'toutvert is ambiguous. Both of the following properties can exist:

 {
     "name": "Wheelchair access : no",
     "value": true,
     "code": "WHEELCHAIR_ACCESS",
 }

 {
     "name": "Wheelchair access : yes",
     "value": true,
     "code": "WHEELCHAIR_ACCESS",
 }
*/
export const accommodationProps = {
  AIR_CONDITIONING: $pgettext('accommodation property AIR_CONDITIONING', 'Air conditioning'),
  BATHROOM__27267: $pgettext('accommodation property BATHROOM__27267', 'Bathroom'),
  CAR_ON_PITCH: $pgettext('accommodation property CAR_ON_PITCH', 'Car on pitch'),
  DELIMITED_PITCH: $pgettext('accommodation property DELIMITED_PITCH', 'Delimited pitch'),
  DRAINAGE_POINT: $pgettext('accommodation property DRAINAGE_POINT', 'Drainage point'),
  ELECTRICITY: $pgettext('accommodation property ELECTRICITY', 'Electricity'),
  HEATING: $pgettext('accommodation property HEATING', 'Heating'),
  KITCHEN_27161: $pgettext('accommodation property KITCHEN_27161', 'Kitchen'),
  MINIMUM_PITCH_SIZE_SQM__: $pgettext('accommodation property MINIMUM_PITCH_SIZE_SQM__', 'Minimum size (sqm)'),
  PARK_ON_PITCH: $pgettext('accommodation property PARK_ON_PITCH', 'Parking on pitch'),
  PETS_ALLOWED: $pgettext('accommodation property PETS_ALLOWED', 'Pets allowed'),
  PETS_NOT_ALLOWED: $pgettext('accommodation property PETS_NOT_ALLOWED', 'Pets not allowed'),
  RUNNING_WATER_CONNECTION: $pgettext('accommodation property RUNNING_WATER_CONNECTION', 'Water connection'),
  SEPARATED_BEDROOMS_: $pgettext('accommodation property SEPARATED_BEDROOMS_', 'Separate bedrooms'),
  SHADED_PITCH: $pgettext('accommodation property SHADED_PITCH', 'Shaded pitch'),
  TELEVISION: $pgettext('accommodation property TELEVISION', 'TV'),
  TERRACE: $pgettext('accommodation property TERRACE', 'Terrace'),
  TOILET_27268: $pgettext('accommodation property TOILET_27268', 'Toilet'),
  UNDELIMITED_PITCH_AREA: $pgettext('accommodation property UNDELIMITED_PITCH_AREA', 'Undelimited pitch area'),
  WHEELCHAIR_ACCESS: $pgettext('accommodation property WHEELCHAIR_ACCESS', 'No wheelchair access'),
  minimumPersons: $pgettext('accommodation property minimumPersons', 'Min persons'),
  maximumPersons: $pgettext('accommodation property maximumPersons', 'Max persons'),
  personsIncluded: $pgettext('accommodation property personsIncluded', 'Persons included'),
  fromPrice: $pgettext('accommodation property fromPrice', 'From price'),
  arrivalWeekdays: $pgettext('accommodation property arrivalWeekdays', 'Arrival weekdays'),
  departureWeekdays: $pgettext('accommodation property departureWeekdays', 'Departure weekdays'),
  minNights: $pgettext('accommodation property minNights', 'Min. nights'),
  maxNights: $pgettext('accommodation property maxNights', 'Max. nights'),
  duration_min: $pgettext('accommodation property minNights', 'Min. nights'),
  duration_max: $pgettext('accommodation property maxNights', 'Max. nights'),
};

export const datePickerLanguageMapping = {
  de: {
    format: 'dd.MM.yyyy',
    placeholder: 'TT.MM.JJJJ',
  },
  en: {
    format: 'dd/MM/yyyy',
    placeholder: 'DD/MM/YYYY',
  },
  es: {
    format: 'dd/MM/yyyy',
    placeholder: 'DD/MM/AAAA',
  },
  fr: {
    format: 'dd.MM.yyyy',
    placeholder: 'JJ.MM.AAAA',
  },
  it: {
    format: 'dd-MM-yyyy',
    placeholder: 'GG-MM-AAAA',
  },
  nl: {
    format: 'dd-MM-yyyy',
    placeholder: 'DD-MM-JJJJ',
  },
};

export default translationFixtures;
